import React, {RefObject, useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {SubmitHandler, useForm} from "react-hook-form";
import {Link, useNavigate, useParams} from "react-router-dom";
import {createProject, getProjectById, updateProject,} from "services/projectService";
import {handleError, ValidationError} from "utils/handleError";
import ProjectForm from "form-models/ProjectForm";
import TextAreaComponent from "../layout/form/TextAreaComponent";
import Input from "../layout/form/Input";
import Label from "../layout/form/Label";
import ErrorForm from "../layout/form/ErrorForm";
import {toast} from "react-toastify";
import {Status} from "../../interfaces/Status";
import {Type} from "../../interfaces/Type";
import {getAllStatus} from "../../services/statusService";
import SelectComponent from "../layout/form/SelectComponent";
import {getAllTypes} from "../../services/typeService";
import  { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from 'date-fns/locale/fr';
import SignUpModalComponent from "components/auth/modals/SignUpModalComponent";
import { isAuthenticated } from "services/authService";
import ReCAPTCHA from "react-google-recaptcha";
import { postByUrlGetResponse } from "services/services";
import LoginModalComponent from "components/auth/modals/LoginModalComponent";
import { Project } from "interfaces/Project";
import Checkbox from "components/layout/form/Checkbox";
import { User } from "interfaces/User";
import { getMe } from "services/userService";
registerLocale('fr', fr);
setDefaultLocale('fr');

// Définir le schéma de validation avec Yup
const projectFormSchema = yup.object().shape({
  lastname: yup.string().required("Un nom est requis"),
  firstname: yup.string().required("Un prénom est requis"),
  email: yup.string().email("L'email doit être valide").required("Un email est requis"),
  name: yup.string().required("Un nom de catégorie requis"),
  company_name: yup.string().required("Le nom de la company est requis"),
  cgu_accepted: yup.bool().oneOf([true], "Vous devez accepter les CGU"),
  communication_accepted: yup.bool().optional(),  
});

interface YourCaptchaType {
  getValue(): string;
  reset(): void;
}

function ProjectFormComponent() {
  const [status, setStatus] = useState<Status[]>([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [defaultType, setDefaultType] = useState("");
  const [type, setType] = useState<Type[]>([]);
  const authenticated = isAuthenticated();
  const site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const captchaRef = useRef<YourCaptchaType | null>(null);
  const [valid_token, setValidToken] = useState<string[] | undefined>([]);

  const [agreement, setAgreement] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreement(event.target.checked);
  }
  // Utilisez le hook useParams pour récupérer les paramètres de l'URL
  const {projectId} = useParams();
  const [errValidation, setErrValidation] = useState<ValidationError | null>(
      null
  );
  const [user, setUser] = useState<User | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm<ProjectForm>({
    defaultValues: async () => {
      if (projectId) {
        try {
          const response = await getProjectById(projectId);

          if(response.status != null) {
            setDefaultValue(response.status.id);
          }
          if(response.type != null) {
            setDefaultType(response.type.id);
          }

          return response;

        } catch (error) {
          handleError(error);
        }
      }
    },
    resolver: yupResolver(projectFormSchema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllStatus();
        if(response[0]) {
          setStatus(response);
          if(!projectId) {
            setDefaultValue(response[0].id);
          }
        }

        const responseType = await getAllTypes();
        if (responseType) {
          setType(responseType);
        }
      } catch (error) {
        handleError(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if(authenticated) {
      const getUser = async () => {
        try {
            const user = await getMe();
            setUser(user);

            // Définir les valeurs initiales du formulaire
            if (user) {
              setValue("lastname", user.lastname);
              setValue("firstname", user.firstname);
              setValue("email", user.email);
            }
        } catch (error) {
          handleError(error);
        }
      };
      getUser();
    }
  }, []);

  const navigate = useNavigate();

  const verifyToken = async (token: string) => {
    try {
      return await postByUrlGetResponse("api/user/verifyToken", {
        reCAPTCHA_TOKEN: token,
      });
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };

  const submitForm = async (data: ProjectForm) => {
    try {
      if (data.status_type_id === "") {
        setValue("status_type_id", defaultValue);
      }
  
      if (data.type_id === "") {
        setValue("type_id", defaultType);
      }

      // Enregistrer uniquement lastname, firstname et email dans le localStorage
      const userBasicInfo = {
        lastname: data.lastname,
        firstname: data.firstname,
        email: data.email,
      };
      sessionStorage.setItem("userBasicInfo", JSON.stringify(userBasicInfo));

      const projectData: ProjectForm = {
        lastname: data.lastname,
        firstname: data.firstname,
        email: data.email,
        name: data.name,
        company_name: data.company_name,
        status_type_id: data.status_type_id,
        type_id: data.type_id,
      };

      if (projectId) {
        await updateProject(projectData, projectId);
        toast.success("Le projet a été mis à jour avec succès.");
        navigate("/project");
      } else {
        const project:Project = await createProject(data);
        toast.success("Le projet a été créé avec succès.");
        navigate("/project/" + project.id + "/quiz");
      }
    } catch (error) {
      const errorMessages = handleError(error);
      setErrValidation(errorMessages);
    }
  };
  
  const onSubmit: SubmitHandler<ProjectForm> = async (data) => {
    if (authenticated) {
      // Si l'utilisateur est authentifié, on soumet les données directement
      await submitForm(data);
    } else {
      // Si l'utilisateur n'est pas authentifié, on vérifie le token captcha
      if (captchaRef.current) {
        let token = captchaRef.current.getValue();
        captchaRef.current.reset();
  
        if (token) {
          try {
            const valid_token = await verifyToken(token);
            if (valid_token && valid_token.message === "OK") {
              await submitForm(data);
            } else {
              toast.error("Une erreur de validation est survenue.");
            }
          } catch (error) {
            const errorMessages = handleError(error);
            setErrValidation(errorMessages);
          }
        } else {
          toast.error("Veuillez accepter le captcha pour continuer.");
        }
      }
    }
  };

  return (
      <div className="w-full">
        <div className="m-4">
          <form
              className="space-y-6"
              method={projectId ? "PUT" : "POST"}
              onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label label={"Nom"} required={true}/>
              <Input<ProjectForm> registerProps={register("lastname")} name={"lastname"} isDisabled={authenticated}/>
              <ErrorForm name={"lastname"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Prénom"} required={true}/>
              <Input<ProjectForm> registerProps={register("firstname")} name={"firstname"} isDisabled={authenticated}/>
              <ErrorForm name={"firstname"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Email"} required={true}/>
              <Input<ProjectForm> registerProps={register("email")} name={"email"} isDisabled={authenticated}/>
              <ErrorForm name={"email"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Nom de l'entreprise"} required={true}/>
              <Input<ProjectForm> registerProps={register("company_name")} name={"company_name"}/>
              <ErrorForm name={"company_name"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Donnez un nom à votre projet pour le retrouver facilement plus tard"} required={true}/>
              <Input<ProjectForm> registerProps={register("name")} name={"name"}/>
              <ErrorForm name={"name"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Vous avez un projet de pricing..."} required={true}/>
              <SelectComponent name={"status_type_id"} registerProps={register("status_type_id")} selectMap={status} selected={defaultValue} haveEmptyEntry={false} />
              <ErrorForm name={"status_type_id"} errors={errors} errValidation={errValidation}/>
            </div>

            <div>
              <Label label={"Il s'agit d'un projet :"} required={true}/>
              <SelectComponent
                name={"type_id"}
                registerProps={register("type_id")}
                selectMap={type}
                selected={defaultType}
                haveEmptyEntry={false}
            />
              <ErrorForm name={"type_id"} errors={errors} errValidation={errValidation}/>
            </div>

            {!authenticated && (
                <div>
                  <div>
                    <div className="flex items-baseline">
                      <Checkbox<ProjectForm>
                        registerProps={register("cgu_accepted")}
                        name={"cgu_accepted"}
                        onChange={handleChange}
                      />
                      <label
                        htmlFor="cgu_accepted"
                        className="text-sm font-medium leading-6 text-gray-900"
                      >
                        &nbsp;Je reconnais avoir pris connaissance des{" "}
                        <Link
                          to="/cgu"
                          style={{ color: "blue", textDecoration: "underline" }}
                          target="_blank"
                        >
                          CGU
                        </Link>{" "}
                        et de la{" "}
                        <Link
                          to="/confidentality"
                          style={{ color: "blue", textDecoration: "underline" }}
                          target="_blank"
                        >
                          Politique de confidentialité
                        </Link>{" "}
                      </label>
                    </div>
                    <ErrorForm
                      name={"cgu_accepted"}
                      errors={errors}
                      errValidation={errValidation}
                    />
                  </div>
                  <div className="flex items-baseline">
                    <Checkbox<ProjectForm>
                      registerProps={register("communication_accepted")}
                      name={"communication_accepted"}
                    />
                    <ErrorForm
                      name={"communication_accepted"}
                      errors={errors}
                      errValidation={errValidation}
                    />
                    <Label
                      label={
                        "Je ne souhaite pas recevoir de communications concernant les offres et produits Converteo"
                      }
                      required={false}
                    />
                  </div>
                </div>
                )}

            {!authenticated && site_key && (
              <ReCAPTCHA
              className="recaptcha"
              sitekey={site_key}
              ref={captchaRef as RefObject<ReCAPTCHA>}
              />
            )}

            <div>
              <button
                  disabled={!agreement && !authenticated}
                  type="submit"
                  className={`flex justify-center rounded-md ${!agreement && !authenticated ? 'bg-primary-grey' : 'bg-primary-blue'} px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-cyan-pastel focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                  >
                Valider
              </button>
            </div>
          </form>
        </div>
      </div>
  );
}

export default ProjectFormComponent;
